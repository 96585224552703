import { useState, forwardRef } from 'react';
import { Button, Row, Col, FormControl } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  copyToClipboard,
  prettifyXml,
  saveToFile,
} from '../../utilities/export';
import {
  generateSessionCardData,
  generateSessionYoutubeCreation,
  generateOBSTemplate,
} from '../../utilities/generate-session-url';
import { YYYYMMDD, GYMapparatusAbbv } from '../../utilities/conversions';
import axios from 'axios';
import { alertActions } from '../../redux/_actions';

const SNIPPET_TYPE = {
  MATCHCARD: 'Match Card',
  YOUTUBE: 'YouTube Creation',
  APIJSON: 'API JSON',
  APIXML: 'API XML',
  OBSTEMPLATE: 'OBS Template',
  URLS: 'Session URLs',
};

export const SessionAdminFunctions = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const {
    MATCHCARD,
    YOUTUBE,
    APIJSON,
    APIXML,
    OBSTEMPLATE,
    URLS,
  } = SNIPPET_TYPE;
  const { sessionId } = props;
  const [text, setText] = useState('');
  const [type, setType] = useState(null);
  const session = useSelector((state) => state.admin.sessions.byId[sessionId]);
  const sessionKey = session.sessionKey;
  const sessionTeams = useSelector((state) => state.admin.sessionTeams);
  const sessionApparatus = GYMapparatusAbbv(session.gender);
  const teams = session.sessionTeams.items.map(
    (stId) => sessionTeams.byId[stId].team
  );

  function printObjectDetailed(obj) {
    let result = '';

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        result += `   ${key}: "${obj[key]}",\n`;
      }
    }

    return `{\n${result}}`;
  }

  const handleCopy = (data, type) => {
    copyToClipboard(data);
    dispatch(alertActions.success(`${type} data copied to clipboard.`));
  };

  const handleAsync = async (type) => {
    let urlType = '';

    switch (type) {
      case APIJSON:
        urlType = 'json';
        break;
      case APIXML:
        urlType = 'xml';
        break;
      default:
        break;
    }

    const req = `https://api.virti.us/session/${sessionKey}/${urlType}`;
    await axios
      .get(req, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        const data = response?.data;

        if (data) {
          let formattedData = '';

          if (urlType === 'json') {
            formattedData = JSON.stringify(data, null, 2);
          }

          if (urlType === 'xml') {
            formattedData = prettifyXml(data);
          }

          handleCopy(formattedData, type);
          setText(formattedData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleShow = (type) => {
    let cardData = '';
    dispatch(alertActions.clear());
    setType(type);

    switch (type) {
      case YOUTUBE:
        cardData = JSON.stringify(
          generateSessionYoutubeCreation(session),
          null,
          2
        );
        handleCopy(cardData, type);
        setText(cardData);
        break;
      case MATCHCARD:
        cardData = printObjectDetailed(generateSessionCardData(session, teams));
        handleCopy(cardData, type);
        setText(cardData);
        break;
      case APIJSON:
        handleAsync(type);
        break;
      case APIXML:
        handleAsync(type);
        break;
      case OBSTEMPLATE:
        cardData = JSON.stringify(generateOBSTemplate(session), null, 2);
        const date = new Date(session.startAt);
        handleCopy(cardData, type);
        setText(cardData);
        saveToFile(`${YYYYMMDD(date, false)} - ${session.name}.json`, cardData);
        break;
      case URLS:
        const baseUrl = `https://virti.us/session?s=${sessionKey}`;
        const apiURL = `https://api.virti.us/session/${sessionKey}/`;
        const scoreboardLetters = Array.from({ length: teams.length }, (_, i) =>
          String.fromCharCode('a'.charCodeAt(0) + i)
        );
        const modifiers = [
          ...scoreboardLetters.map((letter) => `&scoreboard=${letter}`),
          ...sessionApparatus.map((app) => `&scoreboard=${app.toLowerCase()}`),
          ...sessionApparatus.map(
            (app) => `&scoreboard=${app.toLowerCase()}&theme=dark&size=sm`
          ),
          '&scoreboard=teams',
          '&scoreboard=teamsbyapparatus',
          '&leaderboard',
          '&beta=quad',
        ];
        const apiModifiers = ['json', 'xml'];
        cardData = 'Session:\n';
        cardData =
          cardData +
          baseUrl +
          '\n\nScoreboards:\n' +
          modifiers.map((mod) => `${baseUrl}${mod}`).join('\n');
        cardData =
          cardData +
          '\n\nAPI:\n' +
          apiModifiers.map((mod) => `${apiURL}${mod}`).join('\n');
        handleCopy(cardData, type);
        setText(cardData);
        break;
      default:
        break;
    }
  };

  const matchData = (
    <>
      <Row style={{ padding: '1rem' }}>
        <Col
          xs={3}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button variant="secondary" onClick={() => handleShow(MATCHCARD)}>
            Match Card Data
          </Button>
        </Col>
        <Col
          xs={3}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button variant="secondary" onClick={() => handleShow(YOUTUBE)}>
            Youtube Stream Creation
          </Button>
        </Col>
        <Col
          xs={3}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button variant="secondary" onClick={() => handleShow(APIJSON)}>
            API Data (JSON)
          </Button>
        </Col>
        <Col
          xs={3}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button variant="secondary" onClick={() => handleShow(APIXML)}>
            API Data (XML)
          </Button>
        </Col>
      </Row>
      <Row style={{ padding: '1rem' }}>
        <Col
          xs={3}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button variant="secondary" onClick={() => handleShow(OBSTEMPLATE)}>
            OBS Template
          </Button>
        </Col>
        <Col
          xs={3}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button variant="secondary" onClick={() => handleShow(URLS)}>
            Session URLs
          </Button>
        </Col>
      </Row>
    </>
  );

  const textArea = (
    <Row style={{ padding: '1rem' }}>
      {type === URLS ? (
        <div
          style={{
            minHeight: '50vh',
            width: '100%',
            padding: '1rem',
            backgroundColor: '#fff',
            border: '1px solid #ced4da',
            borderRadius: '.25rem',
            whiteSpace: 'pre-wrap', // Ensures spaces and line breaks are preserved
          }}
        >
          {text.split('\n').map((line, index) => {
            // Use the original line without trimming to preserve leading/trailing spaces
            const isURL = /^(https?:\/\/[^\s]+)$/i.test(line.trim());

            if (line === '') {
              // Render a blank line as <br>
              return (
                <div key={index}>
                  <br />
                </div>
              );
            }

            return (
              <div key={index}>
                {isURL ? (
                  <a
                    href={line.trim()}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {line.trim()}
                  </a>
                ) : (
                  <span>{line}</span>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <FormControl
          as="textarea"
          style={{ minHeight: '50vh', padding: '1rem' }}
          value={text ?? ''}
          onChange={(e) => setText(e.target.value)}
        />
      )}
    </Row>
  );

  return (
    <>
      {matchData}
      {textArea}
    </>
  );
});
