/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createStreamWowza = /* GraphQL */ `
  mutation CreateStreamWowza($payload: String!) {
    createStreamWowza(payload: $payload) {
      id
      streamId
      ingestURL
      outputURL
      tempOutputURL
      vodURL
      recordingURL
      recordingId
      sessionKey
      sessionId
      index
      meta
      stats
      error
      status
      vodMeta
      recordingMeta
      createdAt
      updatedAt
      owner
      type
      title
      _version
      _deleted
      _lastChangedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      stages {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const batchCreateRosterLinks = /* GraphQL */ `
  mutation BatchCreateRosterLinks($rosterLinks: [CreateBatchRosterLinkInput]) {
    batchCreateRosterLinks(rosterLinks: $rosterLinks) {
      id
      rosterId
      athleteId
      classYear
      position
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      athlete {
        id
        teamAffiliations
        userId
        gender
        name
        firstName
        lastName
        birthday
        homeTown
        country
        profileImg
        altNames
        slug
        type
        colors
        status
        rtnId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      roster {
        id
        teamId
        activeDate
        archived
        title
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const batchUpdateRosterLinks = /* GraphQL */ `
  mutation BatchUpdateRosterLinks($rosterLinks: [UpdateBatchRosterLinkInput]) {
    batchUpdateRosterLinks(rosterLinks: $rosterLinks) {
      id
      rosterId
      athleteId
      classYear
      position
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      athlete {
        id
        teamAffiliations
        userId
        gender
        name
        firstName
        lastName
        birthday
        homeTown
        country
        profileImg
        altNames
        slug
        type
        colors
        status
        rtnId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      roster {
        id
        teamId
        activeDate
        archived
        title
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const batchCreateSessionJudges = /* GraphQL */ `
  mutation BatchCreateSessionJudges(
    $sessionJudges: [CreateBatchSessionJudgeInput]
  ) {
    batchCreateSessionJudges(sessionJudges: $sessionJudges) {
      id
      sessionId
      userId
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        email
        status
        athleteId
        judgeType
        judgeTypeProxy
        createdAt
        updatedAt
        name
        firstName
        lastName
        location
        handle
        colors
        profileImg
        googleId
        facebookId
        appleId
        cognitoId
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const batchUpdateSessionJudges = /* GraphQL */ `
  mutation BatchUpdateSessionJudges(
    $sessionJudges: [UpdateBatchSessionJudgeInput]
  ) {
    batchUpdateSessionJudges(sessionJudges: $sessionJudges) {
      id
      sessionId
      userId
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        email
        status
        athleteId
        judgeType
        judgeTypeProxy
        createdAt
        updatedAt
        name
        firstName
        lastName
        location
        handle
        colors
        profileImg
        googleId
        facebookId
        appleId
        cognitoId
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      status
      athleteId
      judgeType
      judgeTypeProxy
      createdAt
      updatedAt
      name
      firstName
      lastName
      location
      handle
      colors
      profileImg
      googleId
      facebookId
      appleId
      cognitoId
      _version
      _deleted
      _lastChangedAt
      owner
      coach {
        nextToken
        startedAt
        __typename
      }
      judgeSessions {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      status
      athleteId
      judgeType
      judgeTypeProxy
      createdAt
      updatedAt
      name
      firstName
      lastName
      location
      handle
      colors
      profileImg
      googleId
      facebookId
      appleId
      cognitoId
      _version
      _deleted
      _lastChangedAt
      owner
      coach {
        nextToken
        startedAt
        __typename
      }
      judgeSessions {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      status
      athleteId
      judgeType
      judgeTypeProxy
      createdAt
      updatedAt
      name
      firstName
      lastName
      location
      handle
      colors
      profileImg
      googleId
      facebookId
      appleId
      cognitoId
      _version
      _deleted
      _lastChangedAt
      owner
      coach {
        nextToken
        startedAt
        __typename
      }
      judgeSessions {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createSession = /* GraphQL */ `
  mutation CreateSession(
    $input: CreateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    createSession(input: $input, condition: $condition) {
      id
      sessionKey
      name
      type
      gender
      judging
      judgingRequired
      competition
      apparatus
      access
      startAt
      endAt
      status
      teamScoring
      alternating
      judgePanel
      createdAt
      updatedAt
      admins
      producers
      coaches
      judges
      producer
      rtnId
      hostId
      hostType
      location
      attendance
      _version
      _deleted
      _lastChangedAt
      owner
      rotations {
        nextToken
        startedAt
        __typename
      }
      squads {
        nextToken
        startedAt
        __typename
      }
      judgeAssignments {
        nextToken
        startedAt
        __typename
      }
      sessionJudges {
        nextToken
        startedAt
        __typename
      }
      sessionTeams {
        nextToken
        startedAt
        __typename
      }
      rosters {
        nextToken
        startedAt
        __typename
      }
      lineups {
        nextToken
        startedAt
        __typename
      }
      streams {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateSession = /* GraphQL */ `
  mutation UpdateSession(
    $input: UpdateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    updateSession(input: $input, condition: $condition) {
      id
      sessionKey
      name
      type
      gender
      judging
      judgingRequired
      competition
      apparatus
      access
      startAt
      endAt
      status
      teamScoring
      alternating
      judgePanel
      createdAt
      updatedAt
      admins
      producers
      coaches
      judges
      producer
      rtnId
      hostId
      hostType
      location
      attendance
      _version
      _deleted
      _lastChangedAt
      owner
      rotations {
        nextToken
        startedAt
        __typename
      }
      squads {
        nextToken
        startedAt
        __typename
      }
      judgeAssignments {
        nextToken
        startedAt
        __typename
      }
      sessionJudges {
        nextToken
        startedAt
        __typename
      }
      sessionTeams {
        nextToken
        startedAt
        __typename
      }
      rosters {
        nextToken
        startedAt
        __typename
      }
      lineups {
        nextToken
        startedAt
        __typename
      }
      streams {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteSession = /* GraphQL */ `
  mutation DeleteSession(
    $input: DeleteSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    deleteSession(input: $input, condition: $condition) {
      id
      sessionKey
      name
      type
      gender
      judging
      judgingRequired
      competition
      apparatus
      access
      startAt
      endAt
      status
      teamScoring
      alternating
      judgePanel
      createdAt
      updatedAt
      admins
      producers
      coaches
      judges
      producer
      rtnId
      hostId
      hostType
      location
      attendance
      _version
      _deleted
      _lastChangedAt
      owner
      rotations {
        nextToken
        startedAt
        __typename
      }
      squads {
        nextToken
        startedAt
        __typename
      }
      judgeAssignments {
        nextToken
        startedAt
        __typename
      }
      sessionJudges {
        nextToken
        startedAt
        __typename
      }
      sessionTeams {
        nextToken
        startedAt
        __typename
      }
      rosters {
        nextToken
        startedAt
        __typename
      }
      lineups {
        nextToken
        startedAt
        __typename
      }
      streams {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createRotation = /* GraphQL */ `
  mutation CreateRotation(
    $input: CreateRotationInput!
    $condition: ModelRotationConditionInput
  ) {
    createRotation(input: $input, condition: $condition) {
      id
      sessionId
      order
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      stages {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateRotation = /* GraphQL */ `
  mutation UpdateRotation(
    $input: UpdateRotationInput!
    $condition: ModelRotationConditionInput
  ) {
    updateRotation(input: $input, condition: $condition) {
      id
      sessionId
      order
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      stages {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteRotation = /* GraphQL */ `
  mutation DeleteRotation(
    $input: DeleteRotationInput!
    $condition: ModelRotationConditionInput
  ) {
    deleteRotation(input: $input, condition: $condition) {
      id
      sessionId
      order
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      stages {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createStage = /* GraphQL */ `
  mutation CreateStage(
    $input: CreateStageInput!
    $condition: ModelStageConditionInput
  ) {
    createStage(input: $input, condition: $condition) {
      id
      sessionId
      rotationId
      status
      apparatus
      squadId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      rotation {
        id
        sessionId
        order
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      streams {
        nextToken
        startedAt
        __typename
      }
      judges {
        nextToken
        startedAt
        __typename
      }
      squad {
        id
        sessionId
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      routines {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateStage = /* GraphQL */ `
  mutation UpdateStage(
    $input: UpdateStageInput!
    $condition: ModelStageConditionInput
  ) {
    updateStage(input: $input, condition: $condition) {
      id
      sessionId
      rotationId
      status
      apparatus
      squadId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      rotation {
        id
        sessionId
        order
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      streams {
        nextToken
        startedAt
        __typename
      }
      judges {
        nextToken
        startedAt
        __typename
      }
      squad {
        id
        sessionId
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      routines {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteStage = /* GraphQL */ `
  mutation DeleteStage(
    $input: DeleteStageInput!
    $condition: ModelStageConditionInput
  ) {
    deleteStage(input: $input, condition: $condition) {
      id
      sessionId
      rotationId
      status
      apparatus
      squadId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      rotation {
        id
        sessionId
        order
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      streams {
        nextToken
        startedAt
        __typename
      }
      judges {
        nextToken
        startedAt
        __typename
      }
      squad {
        id
        sessionId
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      routines {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createStageStream = /* GraphQL */ `
  mutation CreateStageStream(
    $input: CreateStageStreamInput!
    $condition: ModelStageStreamConditionInput
  ) {
    createStageStream(input: $input, condition: $condition) {
      id
      sessionId
      rotationId
      stageId
      streamId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      stage {
        id
        sessionId
        rotationId
        status
        apparatus
        squadId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      stream {
        id
        streamId
        ingestURL
        outputURL
        tempOutputURL
        vodURL
        recordingURL
        recordingId
        sessionKey
        sessionId
        index
        meta
        stats
        error
        status
        vodMeta
        recordingMeta
        createdAt
        updatedAt
        owner
        type
        title
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateStageStream = /* GraphQL */ `
  mutation UpdateStageStream(
    $input: UpdateStageStreamInput!
    $condition: ModelStageStreamConditionInput
  ) {
    updateStageStream(input: $input, condition: $condition) {
      id
      sessionId
      rotationId
      stageId
      streamId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      stage {
        id
        sessionId
        rotationId
        status
        apparatus
        squadId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      stream {
        id
        streamId
        ingestURL
        outputURL
        tempOutputURL
        vodURL
        recordingURL
        recordingId
        sessionKey
        sessionId
        index
        meta
        stats
        error
        status
        vodMeta
        recordingMeta
        createdAt
        updatedAt
        owner
        type
        title
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteStageStream = /* GraphQL */ `
  mutation DeleteStageStream(
    $input: DeleteStageStreamInput!
    $condition: ModelStageStreamConditionInput
  ) {
    deleteStageStream(input: $input, condition: $condition) {
      id
      sessionId
      rotationId
      stageId
      streamId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      stage {
        id
        sessionId
        rotationId
        status
        apparatus
        squadId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      stream {
        id
        streamId
        ingestURL
        outputURL
        tempOutputURL
        vodURL
        recordingURL
        recordingId
        sessionKey
        sessionId
        index
        meta
        stats
        error
        status
        vodMeta
        recordingMeta
        createdAt
        updatedAt
        owner
        type
        title
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const createStageJudge = /* GraphQL */ `
  mutation CreateStageJudge(
    $input: CreateStageJudgeInput!
    $condition: ModelStageJudgeConditionInput
  ) {
    createStageJudge(input: $input, condition: $condition) {
      id
      sessionId
      rotationId
      stageId
      sessionJudgeAssignmentId
      scoreTypes
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      stage {
        id
        sessionId
        rotationId
        status
        apparatus
        squadId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      sessionJudgeAssignment {
        id
        sessionId
        judgeId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const updateStageJudge = /* GraphQL */ `
  mutation UpdateStageJudge(
    $input: UpdateStageJudgeInput!
    $condition: ModelStageJudgeConditionInput
  ) {
    updateStageJudge(input: $input, condition: $condition) {
      id
      sessionId
      rotationId
      stageId
      sessionJudgeAssignmentId
      scoreTypes
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      stage {
        id
        sessionId
        rotationId
        status
        apparatus
        squadId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      sessionJudgeAssignment {
        id
        sessionId
        judgeId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const deleteStageJudge = /* GraphQL */ `
  mutation DeleteStageJudge(
    $input: DeleteStageJudgeInput!
    $condition: ModelStageJudgeConditionInput
  ) {
    deleteStageJudge(input: $input, condition: $condition) {
      id
      sessionId
      rotationId
      stageId
      sessionJudgeAssignmentId
      scoreTypes
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      stage {
        id
        sessionId
        rotationId
        status
        apparatus
        squadId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      sessionJudgeAssignment {
        id
        sessionId
        judgeId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const createSquad = /* GraphQL */ `
  mutation CreateSquad(
    $input: CreateSquadInput!
    $condition: ModelSquadConditionInput
  ) {
    createSquad(input: $input, condition: $condition) {
      id
      sessionId
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      rosters {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateSquad = /* GraphQL */ `
  mutation UpdateSquad(
    $input: UpdateSquadInput!
    $condition: ModelSquadConditionInput
  ) {
    updateSquad(input: $input, condition: $condition) {
      id
      sessionId
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      rosters {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteSquad = /* GraphQL */ `
  mutation DeleteSquad(
    $input: DeleteSquadInput!
    $condition: ModelSquadConditionInput
  ) {
    deleteSquad(input: $input, condition: $condition) {
      id
      sessionId
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      rosters {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createLineupMember = /* GraphQL */ `
  mutation CreateLineupMember(
    $input: CreateLineupMemberInput!
    $condition: ModelLineupMemberConditionInput
  ) {
    createLineupMember(input: $input, condition: $condition) {
      id
      sessionId
      sessionRosterId
      athleteContextId
      apparatus
      position
      routineId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      athleteContext {
        id
        athleteId
        level
        division
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      sessionRoster {
        id
        rosterId
        sessionId
        squadId
        squadStartPosition
        lineupId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      routine {
        id
        sessionId
        rotationId
        stageId
        athleteId
        athleteContextId
        apparatus
        order
        rotation
        lineupId
        status
        score
        isEdited
        createdAt
        startedAt
        completedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const updateLineupMember = /* GraphQL */ `
  mutation UpdateLineupMember(
    $input: UpdateLineupMemberInput!
    $condition: ModelLineupMemberConditionInput
  ) {
    updateLineupMember(input: $input, condition: $condition) {
      id
      sessionId
      sessionRosterId
      athleteContextId
      apparatus
      position
      routineId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      athleteContext {
        id
        athleteId
        level
        division
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      sessionRoster {
        id
        rosterId
        sessionId
        squadId
        squadStartPosition
        lineupId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      routine {
        id
        sessionId
        rotationId
        stageId
        athleteId
        athleteContextId
        apparatus
        order
        rotation
        lineupId
        status
        score
        isEdited
        createdAt
        startedAt
        completedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const deleteLineupMember = /* GraphQL */ `
  mutation DeleteLineupMember(
    $input: DeleteLineupMemberInput!
    $condition: ModelLineupMemberConditionInput
  ) {
    deleteLineupMember(input: $input, condition: $condition) {
      id
      sessionId
      sessionRosterId
      athleteContextId
      apparatus
      position
      routineId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      athleteContext {
        id
        athleteId
        level
        division
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      sessionRoster {
        id
        rosterId
        sessionId
        squadId
        squadStartPosition
        lineupId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      routine {
        id
        sessionId
        rotationId
        stageId
        athleteId
        athleteContextId
        apparatus
        order
        rotation
        lineupId
        status
        score
        isEdited
        createdAt
        startedAt
        completedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const createAthleteContext = /* GraphQL */ `
  mutation CreateAthleteContext(
    $input: CreateAthleteContextInput!
    $condition: ModelAthleteContextConditionInput
  ) {
    createAthleteContext(input: $input, condition: $condition) {
      id
      athleteId
      level
      division
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      athlete {
        id
        teamAffiliations
        userId
        gender
        name
        firstName
        lastName
        birthday
        homeTown
        country
        profileImg
        altNames
        slug
        type
        colors
        status
        rtnId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      rosters {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateAthleteContext = /* GraphQL */ `
  mutation UpdateAthleteContext(
    $input: UpdateAthleteContextInput!
    $condition: ModelAthleteContextConditionInput
  ) {
    updateAthleteContext(input: $input, condition: $condition) {
      id
      athleteId
      level
      division
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      athlete {
        id
        teamAffiliations
        userId
        gender
        name
        firstName
        lastName
        birthday
        homeTown
        country
        profileImg
        altNames
        slug
        type
        colors
        status
        rtnId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      rosters {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteAthleteContext = /* GraphQL */ `
  mutation DeleteAthleteContext(
    $input: DeleteAthleteContextInput!
    $condition: ModelAthleteContextConditionInput
  ) {
    deleteAthleteContext(input: $input, condition: $condition) {
      id
      athleteId
      level
      division
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      athlete {
        id
        teamAffiliations
        userId
        gender
        name
        firstName
        lastName
        birthday
        homeTown
        country
        profileImg
        altNames
        slug
        type
        colors
        status
        rtnId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      rosters {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createRosterLink = /* GraphQL */ `
  mutation CreateRosterLink(
    $input: CreateRosterLinkInput!
    $condition: ModelRosterLinkConditionInput
  ) {
    createRosterLink(input: $input, condition: $condition) {
      id
      rosterId
      athleteId
      classYear
      position
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      athlete {
        id
        teamAffiliations
        userId
        gender
        name
        firstName
        lastName
        birthday
        homeTown
        country
        profileImg
        altNames
        slug
        type
        colors
        status
        rtnId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      roster {
        id
        teamId
        activeDate
        archived
        title
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const updateRosterLink = /* GraphQL */ `
  mutation UpdateRosterLink(
    $input: UpdateRosterLinkInput!
    $condition: ModelRosterLinkConditionInput
  ) {
    updateRosterLink(input: $input, condition: $condition) {
      id
      rosterId
      athleteId
      classYear
      position
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      athlete {
        id
        teamAffiliations
        userId
        gender
        name
        firstName
        lastName
        birthday
        homeTown
        country
        profileImg
        altNames
        slug
        type
        colors
        status
        rtnId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      roster {
        id
        teamId
        activeDate
        archived
        title
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const deleteRosterLink = /* GraphQL */ `
  mutation DeleteRosterLink(
    $input: DeleteRosterLinkInput!
    $condition: ModelRosterLinkConditionInput
  ) {
    deleteRosterLink(input: $input, condition: $condition) {
      id
      rosterId
      athleteId
      classYear
      position
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      athlete {
        id
        teamAffiliations
        userId
        gender
        name
        firstName
        lastName
        birthday
        homeTown
        country
        profileImg
        altNames
        slug
        type
        colors
        status
        rtnId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      roster {
        id
        teamId
        activeDate
        archived
        title
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const createAthlete = /* GraphQL */ `
  mutation CreateAthlete(
    $input: CreateAthleteInput!
    $condition: ModelAthleteConditionInput
  ) {
    createAthlete(input: $input, condition: $condition) {
      id
      teamAffiliations
      userId
      gender
      name
      firstName
      lastName
      birthday
      homeTown
      country
      profileImg
      altNames
      slug
      type
      colors
      status
      rtnId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      contexts {
        nextToken
        startedAt
        __typename
      }
      rosters {
        nextToken
        startedAt
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateAthlete = /* GraphQL */ `
  mutation UpdateAthlete(
    $input: UpdateAthleteInput!
    $condition: ModelAthleteConditionInput
  ) {
    updateAthlete(input: $input, condition: $condition) {
      id
      teamAffiliations
      userId
      gender
      name
      firstName
      lastName
      birthday
      homeTown
      country
      profileImg
      altNames
      slug
      type
      colors
      status
      rtnId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      contexts {
        nextToken
        startedAt
        __typename
      }
      rosters {
        nextToken
        startedAt
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteAthlete = /* GraphQL */ `
  mutation DeleteAthlete(
    $input: DeleteAthleteInput!
    $condition: ModelAthleteConditionInput
  ) {
    deleteAthlete(input: $input, condition: $condition) {
      id
      teamAffiliations
      userId
      gender
      name
      firstName
      lastName
      birthday
      homeTown
      country
      profileImg
      altNames
      slug
      type
      colors
      status
      rtnId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      contexts {
        nextToken
        startedAt
        __typename
      }
      rosters {
        nextToken
        startedAt
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createTeamCoach = /* GraphQL */ `
  mutation CreateTeamCoach(
    $input: CreateTeamCoachInput!
    $condition: ModelTeamCoachConditionInput
  ) {
    createTeamCoach(input: $input, condition: $condition) {
      id
      teamId
      userId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        email
        status
        athleteId
        judgeType
        judgeTypeProxy
        createdAt
        updatedAt
        name
        firstName
        lastName
        location
        handle
        colors
        profileImg
        googleId
        facebookId
        appleId
        cognitoId
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        status
        rtnId
        triCode
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const updateTeamCoach = /* GraphQL */ `
  mutation UpdateTeamCoach(
    $input: UpdateTeamCoachInput!
    $condition: ModelTeamCoachConditionInput
  ) {
    updateTeamCoach(input: $input, condition: $condition) {
      id
      teamId
      userId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        email
        status
        athleteId
        judgeType
        judgeTypeProxy
        createdAt
        updatedAt
        name
        firstName
        lastName
        location
        handle
        colors
        profileImg
        googleId
        facebookId
        appleId
        cognitoId
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        status
        rtnId
        triCode
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const deleteTeamCoach = /* GraphQL */ `
  mutation DeleteTeamCoach(
    $input: DeleteTeamCoachInput!
    $condition: ModelTeamCoachConditionInput
  ) {
    deleteTeamCoach(input: $input, condition: $condition) {
      id
      teamId
      userId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        email
        status
        athleteId
        judgeType
        judgeTypeProxy
        createdAt
        updatedAt
        name
        firstName
        lastName
        location
        handle
        colors
        profileImg
        googleId
        facebookId
        appleId
        cognitoId
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        status
        rtnId
        triCode
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const createJudge = /* GraphQL */ `
  mutation CreateJudge(
    $input: CreateJudgeInput!
    $condition: ModelJudgeConditionInput
  ) {
    createJudge(input: $input, condition: $condition) {
      id
      name
      womensBrevet
      womensNational
      womensLevel10
      mensBrevet
      mensOptional
      mensCompulsory
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      sessionAssignments {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateJudge = /* GraphQL */ `
  mutation UpdateJudge(
    $input: UpdateJudgeInput!
    $condition: ModelJudgeConditionInput
  ) {
    updateJudge(input: $input, condition: $condition) {
      id
      name
      womensBrevet
      womensNational
      womensLevel10
      mensBrevet
      mensOptional
      mensCompulsory
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      sessionAssignments {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteJudge = /* GraphQL */ `
  mutation DeleteJudge(
    $input: DeleteJudgeInput!
    $condition: ModelJudgeConditionInput
  ) {
    deleteJudge(input: $input, condition: $condition) {
      id
      name
      womensBrevet
      womensNational
      womensLevel10
      mensBrevet
      mensOptional
      mensCompulsory
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      sessionAssignments {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createSessionJudgeAssignment = /* GraphQL */ `
  mutation CreateSessionJudgeAssignment(
    $input: CreateSessionJudgeAssignmentInput!
    $condition: ModelSessionJudgeAssignmentConditionInput
  ) {
    createSessionJudgeAssignment(input: $input, condition: $condition) {
      id
      sessionId
      judgeId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      stages {
        nextToken
        startedAt
        __typename
      }
      judge {
        id
        name
        womensBrevet
        womensNational
        womensLevel10
        mensBrevet
        mensOptional
        mensCompulsory
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateSessionJudgeAssignment = /* GraphQL */ `
  mutation UpdateSessionJudgeAssignment(
    $input: UpdateSessionJudgeAssignmentInput!
    $condition: ModelSessionJudgeAssignmentConditionInput
  ) {
    updateSessionJudgeAssignment(input: $input, condition: $condition) {
      id
      sessionId
      judgeId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      stages {
        nextToken
        startedAt
        __typename
      }
      judge {
        id
        name
        womensBrevet
        womensNational
        womensLevel10
        mensBrevet
        mensOptional
        mensCompulsory
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteSessionJudgeAssignment = /* GraphQL */ `
  mutation DeleteSessionJudgeAssignment(
    $input: DeleteSessionJudgeAssignmentInput!
    $condition: ModelSessionJudgeAssignmentConditionInput
  ) {
    deleteSessionJudgeAssignment(input: $input, condition: $condition) {
      id
      sessionId
      judgeId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      stages {
        nextToken
        startedAt
        __typename
      }
      judge {
        id
        name
        womensBrevet
        womensNational
        womensLevel10
        mensBrevet
        mensOptional
        mensCompulsory
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createSessionJudge = /* GraphQL */ `
  mutation CreateSessionJudge(
    $input: CreateSessionJudgeInput!
    $condition: ModelSessionJudgeConditionInput
  ) {
    createSessionJudge(input: $input, condition: $condition) {
      id
      sessionId
      userId
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        email
        status
        athleteId
        judgeType
        judgeTypeProxy
        createdAt
        updatedAt
        name
        firstName
        lastName
        location
        handle
        colors
        profileImg
        googleId
        facebookId
        appleId
        cognitoId
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateSessionJudge = /* GraphQL */ `
  mutation UpdateSessionJudge(
    $input: UpdateSessionJudgeInput!
    $condition: ModelSessionJudgeConditionInput
  ) {
    updateSessionJudge(input: $input, condition: $condition) {
      id
      sessionId
      userId
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        email
        status
        athleteId
        judgeType
        judgeTypeProxy
        createdAt
        updatedAt
        name
        firstName
        lastName
        location
        handle
        colors
        profileImg
        googleId
        facebookId
        appleId
        cognitoId
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteSessionJudge = /* GraphQL */ `
  mutation DeleteSessionJudge(
    $input: DeleteSessionJudgeInput!
    $condition: ModelSessionJudgeConditionInput
  ) {
    deleteSessionJudge(input: $input, condition: $condition) {
      id
      sessionId
      userId
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        email
        status
        athleteId
        judgeType
        judgeTypeProxy
        createdAt
        updatedAt
        name
        firstName
        lastName
        location
        handle
        colors
        profileImg
        googleId
        facebookId
        appleId
        cognitoId
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      name
      slug
      location
      altNames
      gender
      colors
      logos
      type
      status
      rtnId
      triCode
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      coaches {
        nextToken
        startedAt
        __typename
      }
      owner
      sessions {
        nextToken
        startedAt
        __typename
      }
      leagues {
        nextToken
        startedAt
        __typename
      }
      rosters {
        nextToken
        startedAt
        __typename
      }
      lineups {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      name
      slug
      location
      altNames
      gender
      colors
      logos
      type
      status
      rtnId
      triCode
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      coaches {
        nextToken
        startedAt
        __typename
      }
      owner
      sessions {
        nextToken
        startedAt
        __typename
      }
      leagues {
        nextToken
        startedAt
        __typename
      }
      rosters {
        nextToken
        startedAt
        __typename
      }
      lineups {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      name
      slug
      location
      altNames
      gender
      colors
      logos
      type
      status
      rtnId
      triCode
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      coaches {
        nextToken
        startedAt
        __typename
      }
      owner
      sessions {
        nextToken
        startedAt
        __typename
      }
      leagues {
        nextToken
        startedAt
        __typename
      }
      rosters {
        nextToken
        startedAt
        __typename
      }
      lineups {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createSessionTeam = /* GraphQL */ `
  mutation CreateSessionTeam(
    $input: CreateSessionTeamInput!
    $condition: ModelSessionTeamConditionInput
  ) {
    createSessionTeam(input: $input, condition: $condition) {
      id
      sessionId
      teamId
      order
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        status
        rtnId
        triCode
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateSessionTeam = /* GraphQL */ `
  mutation UpdateSessionTeam(
    $input: UpdateSessionTeamInput!
    $condition: ModelSessionTeamConditionInput
  ) {
    updateSessionTeam(input: $input, condition: $condition) {
      id
      sessionId
      teamId
      order
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        status
        rtnId
        triCode
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteSessionTeam = /* GraphQL */ `
  mutation DeleteSessionTeam(
    $input: DeleteSessionTeamInput!
    $condition: ModelSessionTeamConditionInput
  ) {
    deleteSessionTeam(input: $input, condition: $condition) {
      id
      sessionId
      teamId
      order
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        status
        rtnId
        triCode
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createLeague = /* GraphQL */ `
  mutation CreateLeague(
    $input: CreateLeagueInput!
    $condition: ModelLeagueConditionInput
  ) {
    createLeague(input: $input, condition: $condition) {
      id
      name
      altNames
      slug
      colors
      logos
      type
      gender
      createdAt
      updatedAt
      status
      _version
      _deleted
      _lastChangedAt
      owner
      teams {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateLeague = /* GraphQL */ `
  mutation UpdateLeague(
    $input: UpdateLeagueInput!
    $condition: ModelLeagueConditionInput
  ) {
    updateLeague(input: $input, condition: $condition) {
      id
      name
      altNames
      slug
      colors
      logos
      type
      gender
      createdAt
      updatedAt
      status
      _version
      _deleted
      _lastChangedAt
      owner
      teams {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteLeague = /* GraphQL */ `
  mutation DeleteLeague(
    $input: DeleteLeagueInput!
    $condition: ModelLeagueConditionInput
  ) {
    deleteLeague(input: $input, condition: $condition) {
      id
      name
      altNames
      slug
      colors
      logos
      type
      gender
      createdAt
      updatedAt
      status
      _version
      _deleted
      _lastChangedAt
      owner
      teams {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createLeagueTeam = /* GraphQL */ `
  mutation CreateLeagueTeam(
    $input: CreateLeagueTeamInput!
    $condition: ModelLeagueTeamConditionInput
  ) {
    createLeagueTeam(input: $input, condition: $condition) {
      id
      leagueId
      teamId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        status
        rtnId
        triCode
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      league {
        id
        name
        altNames
        slug
        colors
        logos
        type
        gender
        createdAt
        updatedAt
        status
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateLeagueTeam = /* GraphQL */ `
  mutation UpdateLeagueTeam(
    $input: UpdateLeagueTeamInput!
    $condition: ModelLeagueTeamConditionInput
  ) {
    updateLeagueTeam(input: $input, condition: $condition) {
      id
      leagueId
      teamId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        status
        rtnId
        triCode
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      league {
        id
        name
        altNames
        slug
        colors
        logos
        type
        gender
        createdAt
        updatedAt
        status
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteLeagueTeam = /* GraphQL */ `
  mutation DeleteLeagueTeam(
    $input: DeleteLeagueTeamInput!
    $condition: ModelLeagueTeamConditionInput
  ) {
    deleteLeagueTeam(input: $input, condition: $condition) {
      id
      leagueId
      teamId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        status
        rtnId
        triCode
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      league {
        id
        name
        altNames
        slug
        colors
        logos
        type
        gender
        createdAt
        updatedAt
        status
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createScore = /* GraphQL */ `
  mutation CreateScore(
    $input: CreateScoreInput!
    $condition: ModelScoreConditionInput
  ) {
    createScore(input: $input, condition: $condition) {
      id
      routineId
      sessionId
      createdAt
      value
      type
      userId
      locked
      _version
      _deleted
      _lastChangedAt
      updatedAt
      owner
      routine {
        id
        sessionId
        rotationId
        stageId
        athleteId
        athleteContextId
        apparatus
        order
        rotation
        lineupId
        status
        score
        isEdited
        createdAt
        startedAt
        completedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const updateScore = /* GraphQL */ `
  mutation UpdateScore(
    $input: UpdateScoreInput!
    $condition: ModelScoreConditionInput
  ) {
    updateScore(input: $input, condition: $condition) {
      id
      routineId
      sessionId
      createdAt
      value
      type
      userId
      locked
      _version
      _deleted
      _lastChangedAt
      updatedAt
      owner
      routine {
        id
        sessionId
        rotationId
        stageId
        athleteId
        athleteContextId
        apparatus
        order
        rotation
        lineupId
        status
        score
        isEdited
        createdAt
        startedAt
        completedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const deleteScore = /* GraphQL */ `
  mutation DeleteScore(
    $input: DeleteScoreInput!
    $condition: ModelScoreConditionInput
  ) {
    deleteScore(input: $input, condition: $condition) {
      id
      routineId
      sessionId
      createdAt
      value
      type
      userId
      locked
      _version
      _deleted
      _lastChangedAt
      updatedAt
      owner
      routine {
        id
        sessionId
        rotationId
        stageId
        athleteId
        athleteContextId
        apparatus
        order
        rotation
        lineupId
        status
        score
        isEdited
        createdAt
        startedAt
        completedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const createPenalty = /* GraphQL */ `
  mutation CreatePenalty(
    $input: CreatePenaltyInput!
    $condition: ModelPenaltyConditionInput
  ) {
    createPenalty(input: $input, condition: $condition) {
      id
      teamId
      lineupId
      sessionId
      createdAt
      rotationId
      value
      type
      userId
      locked
      notes
      valueType
      status
      _version
      _deleted
      _lastChangedAt
      updatedAt
      owner
      lineup {
        id
        teamId
        sessionId
        eventId
        order
        title
        updatedAt
        createdAt
        colors
        logos
        lineupData
        sessionTeamId
        forceTitle
        individuals
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const updatePenalty = /* GraphQL */ `
  mutation UpdatePenalty(
    $input: UpdatePenaltyInput!
    $condition: ModelPenaltyConditionInput
  ) {
    updatePenalty(input: $input, condition: $condition) {
      id
      teamId
      lineupId
      sessionId
      createdAt
      rotationId
      value
      type
      userId
      locked
      notes
      valueType
      status
      _version
      _deleted
      _lastChangedAt
      updatedAt
      owner
      lineup {
        id
        teamId
        sessionId
        eventId
        order
        title
        updatedAt
        createdAt
        colors
        logos
        lineupData
        sessionTeamId
        forceTitle
        individuals
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const deletePenalty = /* GraphQL */ `
  mutation DeletePenalty(
    $input: DeletePenaltyInput!
    $condition: ModelPenaltyConditionInput
  ) {
    deletePenalty(input: $input, condition: $condition) {
      id
      teamId
      lineupId
      sessionId
      createdAt
      rotationId
      value
      type
      userId
      locked
      notes
      valueType
      status
      _version
      _deleted
      _lastChangedAt
      updatedAt
      owner
      lineup {
        id
        teamId
        sessionId
        eventId
        order
        title
        updatedAt
        createdAt
        colors
        logos
        lineupData
        sessionTeamId
        forceTitle
        individuals
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const createRoster = /* GraphQL */ `
  mutation CreateRoster(
    $input: CreateRosterInput!
    $condition: ModelRosterConditionInput
  ) {
    createRoster(input: $input, condition: $condition) {
      id
      teamId
      activeDate
      archived
      title
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      athletes {
        nextToken
        startedAt
        __typename
      }
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        status
        rtnId
        triCode
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      athleteContexts {
        nextToken
        startedAt
        __typename
      }
      sessions {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateRoster = /* GraphQL */ `
  mutation UpdateRoster(
    $input: UpdateRosterInput!
    $condition: ModelRosterConditionInput
  ) {
    updateRoster(input: $input, condition: $condition) {
      id
      teamId
      activeDate
      archived
      title
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      athletes {
        nextToken
        startedAt
        __typename
      }
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        status
        rtnId
        triCode
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      athleteContexts {
        nextToken
        startedAt
        __typename
      }
      sessions {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteRoster = /* GraphQL */ `
  mutation DeleteRoster(
    $input: DeleteRosterInput!
    $condition: ModelRosterConditionInput
  ) {
    deleteRoster(input: $input, condition: $condition) {
      id
      teamId
      activeDate
      archived
      title
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      athletes {
        nextToken
        startedAt
        __typename
      }
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        status
        rtnId
        triCode
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      athleteContexts {
        nextToken
        startedAt
        __typename
      }
      sessions {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createRosterAthleteContext = /* GraphQL */ `
  mutation CreateRosterAthleteContext(
    $input: CreateRosterAthleteContextInput!
    $condition: ModelRosterAthleteContextConditionInput
  ) {
    createRosterAthleteContext(input: $input, condition: $condition) {
      id
      rosterId
      athleteContextId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      athleteContext {
        id
        athleteId
        level
        division
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      roster {
        id
        teamId
        activeDate
        archived
        title
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateRosterAthleteContext = /* GraphQL */ `
  mutation UpdateRosterAthleteContext(
    $input: UpdateRosterAthleteContextInput!
    $condition: ModelRosterAthleteContextConditionInput
  ) {
    updateRosterAthleteContext(input: $input, condition: $condition) {
      id
      rosterId
      athleteContextId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      athleteContext {
        id
        athleteId
        level
        division
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      roster {
        id
        teamId
        activeDate
        archived
        title
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteRosterAthleteContext = /* GraphQL */ `
  mutation DeleteRosterAthleteContext(
    $input: DeleteRosterAthleteContextInput!
    $condition: ModelRosterAthleteContextConditionInput
  ) {
    deleteRosterAthleteContext(input: $input, condition: $condition) {
      id
      rosterId
      athleteContextId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      athleteContext {
        id
        athleteId
        level
        division
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      roster {
        id
        teamId
        activeDate
        archived
        title
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createSessionRoster = /* GraphQL */ `
  mutation CreateSessionRoster(
    $input: CreateSessionRosterInput!
    $condition: ModelSessionRosterConditionInput
  ) {
    createSessionRoster(input: $input, condition: $condition) {
      id
      rosterId
      sessionId
      squadId
      squadStartPosition
      lineupId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      squad {
        id
        sessionId
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      lineup {
        nextToken
        startedAt
        __typename
      }
      roster {
        id
        teamId
        activeDate
        archived
        title
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateSessionRoster = /* GraphQL */ `
  mutation UpdateSessionRoster(
    $input: UpdateSessionRosterInput!
    $condition: ModelSessionRosterConditionInput
  ) {
    updateSessionRoster(input: $input, condition: $condition) {
      id
      rosterId
      sessionId
      squadId
      squadStartPosition
      lineupId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      squad {
        id
        sessionId
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      lineup {
        nextToken
        startedAt
        __typename
      }
      roster {
        id
        teamId
        activeDate
        archived
        title
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteSessionRoster = /* GraphQL */ `
  mutation DeleteSessionRoster(
    $input: DeleteSessionRosterInput!
    $condition: ModelSessionRosterConditionInput
  ) {
    deleteSessionRoster(input: $input, condition: $condition) {
      id
      rosterId
      sessionId
      squadId
      squadStartPosition
      lineupId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      squad {
        id
        sessionId
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      lineup {
        nextToken
        startedAt
        __typename
      }
      roster {
        id
        teamId
        activeDate
        archived
        title
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createLineup = /* GraphQL */ `
  mutation CreateLineup(
    $input: CreateLineupInput!
    $condition: ModelLineupConditionInput
  ) {
    createLineup(input: $input, condition: $condition) {
      id
      teamId
      sessionId
      eventId
      order
      title
      updatedAt
      createdAt
      colors
      logos
      lineupData
      sessionTeamId
      forceTitle
      individuals
      _version
      _deleted
      _lastChangedAt
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        status
        rtnId
        triCode
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      penalties {
        nextToken
        startedAt
        __typename
      }
      owner
      routines {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateLineup = /* GraphQL */ `
  mutation UpdateLineup(
    $input: UpdateLineupInput!
    $condition: ModelLineupConditionInput
  ) {
    updateLineup(input: $input, condition: $condition) {
      id
      teamId
      sessionId
      eventId
      order
      title
      updatedAt
      createdAt
      colors
      logos
      lineupData
      sessionTeamId
      forceTitle
      individuals
      _version
      _deleted
      _lastChangedAt
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        status
        rtnId
        triCode
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      penalties {
        nextToken
        startedAt
        __typename
      }
      owner
      routines {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteLineup = /* GraphQL */ `
  mutation DeleteLineup(
    $input: DeleteLineupInput!
    $condition: ModelLineupConditionInput
  ) {
    deleteLineup(input: $input, condition: $condition) {
      id
      teamId
      sessionId
      eventId
      order
      title
      updatedAt
      createdAt
      colors
      logos
      lineupData
      sessionTeamId
      forceTitle
      individuals
      _version
      _deleted
      _lastChangedAt
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        status
        rtnId
        triCode
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      penalties {
        nextToken
        startedAt
        __typename
      }
      owner
      routines {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createStream = /* GraphQL */ `
  mutation CreateStream(
    $input: CreateStreamInput!
    $condition: ModelStreamConditionInput
  ) {
    createStream(input: $input, condition: $condition) {
      id
      streamId
      ingestURL
      outputURL
      tempOutputURL
      vodURL
      recordingURL
      recordingId
      sessionKey
      sessionId
      index
      meta
      stats
      error
      status
      vodMeta
      recordingMeta
      createdAt
      updatedAt
      owner
      type
      title
      _version
      _deleted
      _lastChangedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      stages {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateStream = /* GraphQL */ `
  mutation UpdateStream(
    $input: UpdateStreamInput!
    $condition: ModelStreamConditionInput
  ) {
    updateStream(input: $input, condition: $condition) {
      id
      streamId
      ingestURL
      outputURL
      tempOutputURL
      vodURL
      recordingURL
      recordingId
      sessionKey
      sessionId
      index
      meta
      stats
      error
      status
      vodMeta
      recordingMeta
      createdAt
      updatedAt
      owner
      type
      title
      _version
      _deleted
      _lastChangedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      stages {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteStream = /* GraphQL */ `
  mutation DeleteStream(
    $input: DeleteStreamInput!
    $condition: ModelStreamConditionInput
  ) {
    deleteStream(input: $input, condition: $condition) {
      id
      streamId
      ingestURL
      outputURL
      tempOutputURL
      vodURL
      recordingURL
      recordingId
      sessionKey
      sessionId
      index
      meta
      stats
      error
      status
      vodMeta
      recordingMeta
      createdAt
      updatedAt
      owner
      type
      title
      _version
      _deleted
      _lastChangedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      stages {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createOverlay = /* GraphQL */ `
  mutation CreateOverlay(
    $input: CreateOverlayInput!
    $condition: ModelOverlayConditionInput
  ) {
    createOverlay(input: $input, condition: $condition) {
      id
      routineId
      clipId
      streamId
      sessionKey
      sessionId
      command
      delay
      displayAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      stream {
        id
        streamId
        ingestURL
        outputURL
        tempOutputURL
        vodURL
        recordingURL
        recordingId
        sessionKey
        sessionId
        index
        meta
        stats
        error
        status
        vodMeta
        recordingMeta
        createdAt
        updatedAt
        owner
        type
        title
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateOverlay = /* GraphQL */ `
  mutation UpdateOverlay(
    $input: UpdateOverlayInput!
    $condition: ModelOverlayConditionInput
  ) {
    updateOverlay(input: $input, condition: $condition) {
      id
      routineId
      clipId
      streamId
      sessionKey
      sessionId
      command
      delay
      displayAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      stream {
        id
        streamId
        ingestURL
        outputURL
        tempOutputURL
        vodURL
        recordingURL
        recordingId
        sessionKey
        sessionId
        index
        meta
        stats
        error
        status
        vodMeta
        recordingMeta
        createdAt
        updatedAt
        owner
        type
        title
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteOverlay = /* GraphQL */ `
  mutation DeleteOverlay(
    $input: DeleteOverlayInput!
    $condition: ModelOverlayConditionInput
  ) {
    deleteOverlay(input: $input, condition: $condition) {
      id
      routineId
      clipId
      streamId
      sessionKey
      sessionId
      command
      delay
      displayAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        rtnId
        hostId
        hostType
        location
        attendance
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      stream {
        id
        streamId
        ingestURL
        outputURL
        tempOutputURL
        vodURL
        recordingURL
        recordingId
        sessionKey
        sessionId
        index
        meta
        stats
        error
        status
        vodMeta
        recordingMeta
        createdAt
        updatedAt
        owner
        type
        title
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createClip = /* GraphQL */ `
  mutation CreateClip(
    $input: CreateClipInput!
    $condition: ModelClipConditionInput
  ) {
    createClip(input: $input, condition: $condition) {
      id
      routineId
      sessionId
      streamId
      seekStartSeconds
      seekEndSeconds
      startTime
      endTime
      pgmStartTime
      pgmEndTime
      duration
      fileName
      originURL
      owner
      type
      status
      location
      resolution
      fps
      bitrate
      fileSize
      source
      codecs
      other
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      routine {
        id
        sessionId
        rotationId
        stageId
        athleteId
        athleteContextId
        apparatus
        order
        rotation
        lineupId
        status
        score
        isEdited
        createdAt
        startedAt
        completedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const updateClip = /* GraphQL */ `
  mutation UpdateClip(
    $input: UpdateClipInput!
    $condition: ModelClipConditionInput
  ) {
    updateClip(input: $input, condition: $condition) {
      id
      routineId
      sessionId
      streamId
      seekStartSeconds
      seekEndSeconds
      startTime
      endTime
      pgmStartTime
      pgmEndTime
      duration
      fileName
      originURL
      owner
      type
      status
      location
      resolution
      fps
      bitrate
      fileSize
      source
      codecs
      other
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      routine {
        id
        sessionId
        rotationId
        stageId
        athleteId
        athleteContextId
        apparatus
        order
        rotation
        lineupId
        status
        score
        isEdited
        createdAt
        startedAt
        completedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const deleteClip = /* GraphQL */ `
  mutation DeleteClip(
    $input: DeleteClipInput!
    $condition: ModelClipConditionInput
  ) {
    deleteClip(input: $input, condition: $condition) {
      id
      routineId
      sessionId
      streamId
      seekStartSeconds
      seekEndSeconds
      startTime
      endTime
      pgmStartTime
      pgmEndTime
      duration
      fileName
      originURL
      owner
      type
      status
      location
      resolution
      fps
      bitrate
      fileSize
      source
      codecs
      other
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      routine {
        id
        sessionId
        rotationId
        stageId
        athleteId
        athleteContextId
        apparatus
        order
        rotation
        lineupId
        status
        score
        isEdited
        createdAt
        startedAt
        completedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      __typename
    }
  }
`;
export const createRoutine = /* GraphQL */ `
  mutation CreateRoutine(
    $input: CreateRoutineInput!
    $condition: ModelRoutineConditionInput
  ) {
    createRoutine(input: $input, condition: $condition) {
      id
      sessionId
      rotationId
      stageId
      athleteId
      athleteContextId
      apparatus
      order
      rotation
      lineupId
      status
      score
      isEdited
      createdAt
      startedAt
      completedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      stage {
        id
        sessionId
        rotationId
        status
        apparatus
        squadId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      athleteContext {
        id
        athleteId
        level
        division
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      athlete {
        id
        teamAffiliations
        userId
        gender
        name
        firstName
        lastName
        birthday
        homeTown
        country
        profileImg
        altNames
        slug
        type
        colors
        status
        rtnId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      scores {
        nextToken
        startedAt
        __typename
      }
      lineup {
        id
        teamId
        sessionId
        eventId
        order
        title
        updatedAt
        createdAt
        colors
        logos
        lineupData
        sessionTeamId
        forceTitle
        individuals
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      clips {
        nextToken
        startedAt
        __typename
      }
      owner
      inquiries {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateRoutine = /* GraphQL */ `
  mutation UpdateRoutine(
    $input: UpdateRoutineInput!
    $condition: ModelRoutineConditionInput
  ) {
    updateRoutine(input: $input, condition: $condition) {
      id
      sessionId
      rotationId
      stageId
      athleteId
      athleteContextId
      apparatus
      order
      rotation
      lineupId
      status
      score
      isEdited
      createdAt
      startedAt
      completedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      stage {
        id
        sessionId
        rotationId
        status
        apparatus
        squadId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      athleteContext {
        id
        athleteId
        level
        division
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      athlete {
        id
        teamAffiliations
        userId
        gender
        name
        firstName
        lastName
        birthday
        homeTown
        country
        profileImg
        altNames
        slug
        type
        colors
        status
        rtnId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      scores {
        nextToken
        startedAt
        __typename
      }
      lineup {
        id
        teamId
        sessionId
        eventId
        order
        title
        updatedAt
        createdAt
        colors
        logos
        lineupData
        sessionTeamId
        forceTitle
        individuals
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      clips {
        nextToken
        startedAt
        __typename
      }
      owner
      inquiries {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteRoutine = /* GraphQL */ `
  mutation DeleteRoutine(
    $input: DeleteRoutineInput!
    $condition: ModelRoutineConditionInput
  ) {
    deleteRoutine(input: $input, condition: $condition) {
      id
      sessionId
      rotationId
      stageId
      athleteId
      athleteContextId
      apparatus
      order
      rotation
      lineupId
      status
      score
      isEdited
      createdAt
      startedAt
      completedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      stage {
        id
        sessionId
        rotationId
        status
        apparatus
        squadId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      athleteContext {
        id
        athleteId
        level
        division
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      athlete {
        id
        teamAffiliations
        userId
        gender
        name
        firstName
        lastName
        birthday
        homeTown
        country
        profileImg
        altNames
        slug
        type
        colors
        status
        rtnId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      scores {
        nextToken
        startedAt
        __typename
      }
      lineup {
        id
        teamId
        sessionId
        eventId
        order
        title
        updatedAt
        createdAt
        colors
        logos
        lineupData
        sessionTeamId
        forceTitle
        individuals
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      clips {
        nextToken
        startedAt
        __typename
      }
      owner
      inquiries {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createInquiry = /* GraphQL */ `
  mutation CreateInquiry(
    $input: CreateInquiryInput!
    $condition: ModelInquiryConditionInput
  ) {
    createInquiry(input: $input, condition: $condition) {
      id
      sessionId
      routineId
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      routine {
        id
        sessionId
        rotationId
        stageId
        athleteId
        athleteContextId
        apparatus
        order
        rotation
        lineupId
        status
        score
        isEdited
        createdAt
        startedAt
        completedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateInquiry = /* GraphQL */ `
  mutation UpdateInquiry(
    $input: UpdateInquiryInput!
    $condition: ModelInquiryConditionInput
  ) {
    updateInquiry(input: $input, condition: $condition) {
      id
      sessionId
      routineId
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      routine {
        id
        sessionId
        rotationId
        stageId
        athleteId
        athleteContextId
        apparatus
        order
        rotation
        lineupId
        status
        score
        isEdited
        createdAt
        startedAt
        completedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteInquiry = /* GraphQL */ `
  mutation DeleteInquiry(
    $input: DeleteInquiryInput!
    $condition: ModelInquiryConditionInput
  ) {
    deleteInquiry(input: $input, condition: $condition) {
      id
      sessionId
      routineId
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      routine {
        id
        sessionId
        rotationId
        stageId
        athleteId
        athleteContextId
        apparatus
        order
        rotation
        lineupId
        status
        score
        isEdited
        createdAt
        startedAt
        completedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
